import settings from '@/settings'

/**
 * 班級電表資料
 */
class MeterOffLoading {
  /**
   * 取得需量警戒值
   */
  getDemand(schoolId) {
    return axios.get(
      settings.api.fullPath + `/ems/meter/offloading/${schoolId}`
    )
  }

  /**
   * 設定需量警戒值
   */
  setDemand(schoolId, params) {
    return axios.put(
      settings.api.fullPath + `/ems/meter/offloading/${schoolId}`,
      params
    )
  }

  /**
   * 取得卸載方案
   */
  getPlan(schoolId) {
    return axios.get(
      settings.api.fullPath + `/ems/meter/offloading/plan/${schoolId}`
    )
  }

  /**
   * 設定卸載方案
   */
  setPlan(schoolId, params) {
    return axios.put(
      settings.api.fullPath + `/ems/meter/offloading/plan/${schoolId}`,
      params
    )
  }
}

var meterOffLoading = new MeterOffLoading()
export default meterOffLoading
