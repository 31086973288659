<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :disabled="readonly"
    class="mt-0"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        :label="label"
        :prepend-icon="prependIcon"
        :prepend-inner-icon="prependInnerIcon"
        readonly
        v-bind="attrs"
        v-on="on"
        class="date-picker-input"
        hide-details
        solo
        ref="date-picker-input"
      >
        <template v-slot:append>
          <v-btn v-if="dates.length > 1" color="success" @click="commit()"
            >確認</v-btn
          >
          <v-btn v-else color="primary" @click="menu = true">新增日期</v-btn>
        </template>
      </v-text-field>
    </template>
    <v-date-picker v-model="dates" no-title scrollable :range="range">
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'InputDateRangePicker',
  mixins: [],
  components: {},
  props: {
    value: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: '請選擇日期'
    },
    prependIcon: {},
    prependInnerIcon: {
      default: 'mdi-calendar'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    range: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      menu: null,
      dates: []
    }
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function (val, oldVal) {
        this.$nextTick(() => {
          this.dates = val
        })
      }
    },
    dates(newVal, oleVal) {
      if (oleVal && oleVal.length === 1 && newVal && newVal.length === 2) {
        this.menu = false
      }
    }
  },
  created: function () {},
  mounted: function () {
    this.dates = this.value || []
  },
  destroyed() {},
  methods: {
    commit() {
      if (this.dates && this.dates.length === 1) {
        this.dates[1] = this.dates[0]
      }
      this.$emit('input', this.dates)
    }
  }
}
</script>

<style lang="scss" scoped></style>
