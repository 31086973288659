<template>
  <div class="d-flex">
    <v-row>
      <v-col cols="auto" v-for="(item, index) in cardList" :key="index">
        <v-card min-width="200">
          <v-card-subtitle class="pb-0">{{ item.name }}</v-card-subtitle>
          <v-card-title>
            <span
              class="font-weight-bold"
              :class="{
                'error--text': !item.isRegistered,
                'success--text': item.isRegistered,
                'text-decoration-line-through': item.isCanceled
              }"
            >
              {{ item.isRegistered ? '已註冊' : '未註冊' }}
            </span>
            <span v-if="item.isCanceled" class="caption red--text ml-2">
              (已取消)
            </span>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-btn
      color="primary"
      :disabled="!OpenADRData.isRegistered || OpenADRData.isCanceled"
      @click="stopAdrEvent"
      :loading="loadingStopAdrEvent"
      >強制停止 ADR 事件</v-btn
    >
  </div>
</template>

<script>
import OpenADR from '@/api/ems/OpenADR'
import Swal from 'sweetalert2'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    schoolId: {
      type: String,
      require: true
    }
  },
  data: function () {
    return {
      openADRList: [],
      loadingStopAdrEvent: false
    }
  },
  computed: {
    OpenADRData() {
      const OpenADRData = this.openADRList.find(
        (d) => d.schoolId === this.schoolId
      )

      if (!OpenADRData) {
        return {
          isRegistered: false,
          isCanceled: false,
          isRegisteredReport: false
        }
      }
      return OpenADRData
    },
    cardList() {
      return [
        {
          name: 'VEN 狀態',
          isRegistered: this.OpenADRData.isRegistered,
          isCanceled: this.OpenADRData.isCanceled
        },
        {
          name: 'Report 狀態',
          isRegistered: this.OpenADRData.isRegisteredReport
        }
      ]
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {
    this.getOpenADR()
  },
  destroyed() {},
  methods: {
    getOpenADR() {
      OpenADR.ven()
        .then((resp) => {
          this.openADRList = resp.data
        })
        .catch((error) => {
          console.log(error.response)
        })
    },
    async stopAdrEvent() {
      this.loadingStopAdrEvent = true
      try {
        await OpenADR.stopAdrEvent(this.schoolId)
        Swal.fire({
          title: '執行停止成功',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          width: 300
        })
      } catch (error) {
        if (error.response.status === 404) {
          Swal.fire({
            title: '無執行中的事件',
            icon: 'error',
            confirmButtonColor: '#3085d6',
            width: 300
          })
        } else {
          Swal.fire({
            title: '執行停止失敗',
            icon: 'error',
            confirmButtonColor: '#3085d6',
            width: 300
          })
        }
      }
      this.loadingStopAdrEvent = false
    }
  }
}
</script>

<style></style>
